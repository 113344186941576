import { Controller } from "stimulus"

let userSelectByUnit;

export default class extends Controller {
  connect() {
    userSelectByUnit = $('#select-user-id-by-unit').selectize({
      plugins: ["remove_button"],
      valueField: "id",
      labelField: "unit",
      searchField: "unit",
      create: false,
      load: function (query, callback) {
        if (!query.length) return callback();

        $.ajax({
          url: "/ui/select_user.json?q=" + encodeURIComponent(query),
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            callback(res.users);
          },
        });
      },
    });
  }

  disconnect() {
    const need_destroy = userSelectByUnit[0].selectize;
    if(need_destroy) {
      need_destroy.destroy();
    }
  }
}
