import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    action: String
  }

  submit(event) {
    const matchForm = document.getElementById('match-form');
    const submitFormAction = document.getElementById('submit-form-action');
    submitFormAction.value = this.actionValue;
    matchForm.submit()
  }
}
