import { Controller } from "stimulus"

let dashboardOverview;

export default class extends Controller {
  static targets = ["chart"];
  static values = { pause: Number };

  connect() {
    dashboardOverview = echarts.init(this.chartTarget);

    const monthly_events_option = {
      title: {
        text: 'ECharts 入门示例'
      },
      tooltip: {},
      legend: {
        data: ['销量']
      },
      xAxis: {
        data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
      },
      yAxis: {},
      series: [
        {
          name: '销量',
          type: 'bar',
          data: [5, 20, 36, 10, 10, 20]
        }
      ]
    };

    dashboardOverview.setOption(monthly_events_option);
  }

  layout() {
    dashboardOverview.resize();
  }

  disconnect() {
    dashboardOverview.dispose();
  }

}
