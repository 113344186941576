import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "startTime", "endTime", "estimateText" ]
  static values = {
    confirmed: Boolean
  }

  change(event) {
    if (this.confirmedValue) {
      this.confirmedValue = false;
      this.startTimeTarget.style.display = 'none';
      this.endTimeTarget.style.display = 'none';
      this.estimateTextTarget.style.display = '';
    } else {
      this.confirmedValue = true;
      this.startTimeTarget.style.display = '';
      this.endTimeTarget.style.display = '';
      this.estimateTextTarget.style.display = 'none';
    }
  }
}
