import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "associate" ]

  change(event) {
    const match_cateogory_id = event.target.value;
    if(match_cateogory_id == 5) {
      this.associateTarget.style.display = '';
    } else {
      this.associateTarget.style.display = 'none';
    }
    $.ajax('/ui/match_category_changed', {
      data: { match_cateogory_id },
      dataType: 'script'
    });
  }
}
