// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

const importAll = (r) => r.keys().map(r)
importAll(require.context('images', false, /\.(png|jpe?g|svg)$/i));
//importAll(require.context('@fortawesome/fontawesome-pro/webfonts', false, /\.(eot|svg|ttf|woff2?)$/i));

import "core-js/stable";
import "regenerator-runtime/runtime";
import "@stimulus/polyfills";

import "bootstrap";
import "@mixtint/coreui"
import "@selectize/selectize/dist/js/selectize";

import "stylesheets/application"
global.moment = require('moment');
require("tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4")

global.Rails = require("@rails/ujs");
global.Rails.start();

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "turbolinks/coreui";

import * as echarts from 'echarts/core';
import {
  BarChart,
  PieChart
} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  ToolboxComponent,
  LegendComponent,
} from 'echarts/components';
import {
  CanvasRenderer
} from 'echarts/renderers';

echarts.use(
  [TitleComponent, TooltipComponent, GridComponent, ToolboxComponent, LegendComponent,
   BarChart, PieChart,
   CanvasRenderer]
);
global.echarts = echarts;

import "controllers";
import "stylesheets/application.scss";

$.fn.datetimepicker.Constructor.Default = $.extend({},
  $.fn.datetimepicker.Constructor.Default,
  { icons:
    { time: 'fas fa-clock',
      date: 'fas fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-arrow-circle-left',
      next: 'fas fa-arrow-circle-right',
      today: 'far fa-calendar-check-o',
      clear: 'fas fa-trash',
      close: 'far fa-times' } });

global.Trix = require("@mixtint/trix")
require("@rails/actiontext")
